import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import 'react-loading-skeleton/dist/skeleton.css'
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSigninModal } from '../../Components/useModal';
import Host from '../../Components/Host';
import { useFetchQuery, useUpdateMutation, useDeleteMutation } from '../../services/cart';
import LoginForm from '../../Layout/Pages/LoginForm';
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useGetUserDataQuery } from '../../services/login';
import formatPrice from '../../Components/formatPrice';

function CartPage({ getcartDrawer, cartDraweraa }) {
  const { userId, userToken, userEmail, countryCode, cartToken, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily = {
    fontFamily: fontFamilyForAll,
    textAlign: 'start'
  }

  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const getCart = useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const cartData = getCart?.data?.cart

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [])

  const TotalPrice = cartData?.reduce((accumulator, value) => {
    const productPrice = value.prices?.value * value.quantity;
    return accumulator + productPrice
  }, 0);

  const [updatecart, updateresult] = useUpdateMutation()
  const [deletecart, deleteresult] = useDeleteMutation()
  const { isSigninModal, signinModal } = useSigninModal();

  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s]/gi, ' ')?.replace(/ /g, '-')
  }

  const [getlogin, setLogin] = React.useState(false);
  useEffect(() => {
    if (isSigninModal) {
      setLogin(false)
    }
  }, [isSigninModal])

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const cartDrawer = (anchor, open) => (event) => {
    cartDraweraa('', false)
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (getcartDrawer) {
      setState({ ...state, [getcartDrawer]: true });
    }
  }, [getcartDrawer]);


  function closeDrawer() {
    if (document.body.dir === 'rtl') {
      setState({ ...state, left: false });
    } else {
      setState({ ...state, right: false });
    }
  }

  async function goToPurchase() {
    if (userId && userToken) {
      closeDrawer()
      cartDraweraa('', false)
      history.push(`/purchase`)
    } else {
      setLogin(true)
    }
  }

  async function goToAsGuest() {
    closeDrawer()
    cartDraweraa('', false)
    history.push(`/purchase/guest`)
  }


  const soteIds = [3, 1]

  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }


  function getCartDetails() {
    return (
      <>
        {getCart?.isLoading &&
          <div style={{ textAlign: 'center', padding: '10px 0px 10px 0px' }}>
            <CircularProgress style={{ width: '2rem', height: '2rem', color: '#fe7f4a' }} />
          </div>
        }

        {cartData?.length > 0 &&
          <>
            <div className='cp-buttons-sticky'>
              <Button
                size="sm"
                className='cp-buttons'
                onClick={(() => goToPurchase())}
                style={{ background: Host?.bgColor, color: Host?.buttonColor, width: '100%', whiteSpace: 'nowrap', textTransform: 'math-auto', margin: '0px 10px', height: '40px', fontWeight: 400 }}>
                {userId && userToken ?
                  <div>{t('continue_to_checkout')}</div> :
                  <div>{t('Login to checkout')}</div>
                }
              </Button>
              {!userId && !userToken &&
                <Button
                  size="sm"
                  className='cp-outline-buttons'
                  variant='outlined'
                  onClick={(() => goToAsGuest())}
                  style={{ width: '100%', whiteSpace: 'nowrap', textTransform: 'math-auto', margin: '0px 10px', height: '40px', fontWeight: 400 }}
                >
                  <div>{t('Or guest checkout')}</div>
                </Button>
              }
            </div>
            <div className="col-md-12" style={{ padding: '20px' }}>
              <div style={{ padding: '0px' }}>
                <div className="row cp-card-row" style={{ justifyContent: 'center' }}>
                  <div className="col-md-12" style={{ padding: '0px' }}>
                    <div className="row">
                      {cartData?.map((item, i) =>
                        <div className="col-md-12 cp-card-item" style={{ marginBottom: '10px', padding: 0 }} key={i}>
                          <div className="cart-page-card">
                            <div className="img-card">
                              <img
                                className="img-item"
                                src={item.image}
                                loading="lazy"
                                alt={item?.title}
                              />
                            </div>
                            <div className="cart-page-text">
                              <div className="brand">{item.brand}</div>
                              <Link to={`/${languageCode}/product/${item.store_id}/${functionIdLink(item?.product_id, item?.pid, item?.store_id)}/${productTitle(item.title)}`}>
                                <div className="title" style={{ fontSize: '13px', fontWeight: 400 }}>{item.title?.replace(/[^\w\s.,'/:]/gi, ' ')}</div>
                              </Link>
                              {item.current_variant &&
                                <div style={{ padding: '5px 0px 5px 0px' }}>
                                  {Object.entries(item.current_variant || []).map(([key, value], index) => (
                                    <div style={{ fontSize: '12px' }} key={index}><strong>{key}:</strong> {value}</div>
                                  ))}
                                </div>
                              }
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0px 10px 0px' }}>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid #eee', borderRadius: '6px' }}>
                                    <IconButton
                                      size='sm'
                                      style={{ borderRadius: '100px' }}
                                      onClick={(() => (item.quantity > 1) && updatecart({
                                        id: item.id,
                                        cartToken: cartToken,
                                        countryCode: countryCode,
                                        quantity: Number(item.quantity) - 1,
                                      }))}
                                    >
                                      <RemoveIcon style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                    {updateresult?.isLoading && updateresult?.originalArgs?.id === item.id ?
                                      <CircularProgress color="inherit" style={{ width: '1.1rem', height: '1.1rem' }} /> :
                                      <div style={{ padding: '0px 5px 0px 5px' }}>{item.quantity}</div>
                                    }
                                    <IconButton
                                      disabled={item?.inventory ? Number(item.quantity) === item?.qty : Number(item.quantity) === 20}
                                      style={{ borderRadius: '100px' }}
                                      size='sm'
                                      onClick={(() => updatecart({
                                        id: item.id,
                                        cartToken: cartToken,
                                        countryCode: countryCode,
                                        quantity: Number(item.quantity) + 1,
                                      }))}
                                    >
                                      <AddIcon style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                  </div>

                                  <div style={{ margin: '0 10px' }}>
                                    <IconButton
                                      style={{ borderRadius: '100px' }}
                                      // className="cart-remove-button"
                                      size='sm'
                                      variant='soft'
                                      color='danger'
                                      onClick={(() => deletecart({
                                        id: item.id,
                                        cartToken: cartToken,
                                        countryCode: countryCode,
                                        userId: userId,
                                      }))}
                                    >
                                      {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                                        <CircularProgress color="inherit" style={{ width: '14px', height: '14px' }} /> :
                                        <ClearIcon style={{ color: 'tomato', width: '14px', height: '14px' }} />
                                      }
                                    </IconButton>
                                  </div>
                                </div>
                                <div className="price">
                                  {formatPrice(item.prices?.value, item.prices?.currency, 0)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 cp-card-item cpc-button" style={{ padding: 0 }}>
                    <div className="purchase-info-card bb">
                      <div className="purchase-info-sub-card">
                        <div>
                          <h5><strong>{t('order_summary')}</strong></h5>
                          <div className="purchase-infodiv">
                            <div>{t('sub_total')} {`(${cartData?.length} ${t('item')})`}</div>
                            <div>
                              <h5 className='cart-font-size' style={{ margin: 0 }}>
                                {formatPrice(TotalPrice, cartData?.[0]?.prices?.currency, 0)}
                              </h5>
                            </div>
                          </div>

                          <div className="purchase-infodiv">
                            <div>{t('delivery')} {t('fee')} <Link to="/shipping-policy"><small>{t('details')}</small></Link></div>
                            <div>
                              <small>{userData?.contant?.standard_delivery > 0 ?
                                formatPrice(0, userData?.contant?.currency, userData?.contant?.standard_delivery) :
                                t('free')}</small>
                            </div>
                          </div>

                          <div className="purchase-infodiv" style={{ borderBottom: '1px solid #eeee', marginBottom: '0px' }}>
                            <h4><strong>{t('total')}:</strong></h4>
                            <h5 className='cart-font-size'>
                              {formatPrice(TotalPrice, cartData?.[0]?.prices?.currency, userData?.contant?.standard_delivery)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    )
  }

  function checkLogedinOrForm() {
    if (userId && userToken) {
      return false
    } else if (getlogin) {
      return true
    } else {
      return false
    }

  }

  return (
    <React.Fragment>
      {[document.body.dir === 'rtl' ? 'left' : 'right'].map((anchor) => (
        <Drawer
          style={fontFamily}
          className="cart-menu-section side-scroll-menu"
          key={anchor}
          anchor={anchor}
          open={state[anchor]}
          onClose={cartDrawer(anchor, false)}
        >
          <div style={{
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            {checkLogedinOrForm() ?
              <Button
                variant="plain"
                size="sm"
                onClick={(() => setLogin(false))}
                style={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize', padding: '0px' }}
              >
                <ArrowBackOutlinedIcon />
              </Button> :
              <ModalClose id="close-icon" sx={{ position: 'initial' }} />
            }
            <div className='cardTitle'>{t('your_cart')}</div>
          </div>
          <Box
            className="side-dialogue-section"
            style={fontFamily}
            role="presentation"
          >
            <div className="cart-page">
              <div className="search-section">
                <div className="row" style={{ justifyContent: 'center' }}>
                  {getlogin ?
                    <>
                      {userId && userToken ?
                        getCartDetails() :
                        <div style={{ padding: '20px' }}>
                          <div style={{ background: '#fff', marginTop: '15px', border: '1px solid #eee', padding: '10px', borderRadius: '10px' }}>
                            <LoginForm signinModal={signinModal} />
                          </div>
                        </div>
                      }
                    </>
                    :
                    <>
                      {getCartDetails()}
                      {cartData?.length === 0 &&
                        <div className="col-md-7">
                          <div className='cardTitle'>{t('cart_empty')}</div>
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </Box>
        </Drawer>
      ))}
    </React.Fragment>
  );
}

export default CartPage;
