import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import i18next from 'i18next'
import { useDispatch, useSelector } from "react-redux";
import LoginModal from '../../Layout/Pages/LoginModal';
import Languages from '../../Layout/Pages/Languages';
import { useInquiryModal, useSigninModal } from '../useModal';
import { removeToCart, removeUserToState, addSelectedSevenDayaPass, addToLanguage } from "../../Reducers/authReducer";
import SearchBar from "material-ui-search-bar";
import Button from '@mui/joy/Button';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
const { countries, zones } = require("moment-timezone/data/meta/latest.json");
import MenuIcon from '@mui/icons-material/Menu';
import Countries from './Countries';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Drawer from '@mui/joy/Drawer';
import List from '@mui/joy/List';
import ListItemButton from '@mui/joy/ListItemButton';
import ModalClose from '@mui/joy/ModalClose';
import CloseIcon from '@mui/icons-material/Close';
import Host from '../Host';
import { useFetchQuery, useDeleteMutation } from '../../services/cart';
import { useGetUserDataQuery } from '../../services/login';
import CartPage from '../../Pages/CartPage';
import Alert from '@mui/material/Alert';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { useMediaQuery } from 'react-responsive'
import ActiveCountries from './ActiveCountries';
import Domains from './Domains';
import GradeIcon from '@mui/icons-material/Grade';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { colors } from '@material-ui/core';
const moment = require('moment-timezone');

function Header() {
  const { userId, userToken, userEmail, languageCode, countryCode, cartToken } = useSelector((state) => state.auth);
  const isLangaugeCode = languageCode ? languageCode : 'en'
  const history = useHistory();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })

  const timeZoneToCountry = {};
  Object.keys(zones).forEach(z => {
    const cityArr = z.split("/");
    const city = cityArr[cityArr.length - 1];
    timeZoneToCountry[city] = countries[zones[z].countries[0]].abbr;
  });
  let userCountryCode;
  if (Intl) {
    // const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userTimeZone = moment.tz.guess() ? moment.tz.guess() : 'US'
    const tzArr = userTimeZone?.split("/");
    const userCity = tzArr[tzArr.length - 1];
    userCountryCode = timeZoneToCountry[userCity];
  }

  const thisHostname = window.location.hostname?.replace('getthwifty', 'thwifty');

  const currentLanguage = Languages?.find((l) => l.code === i18next?.language)
  const liveDomain = Domains?.find((d) => d.domain?.includes(thisHostname))
  const thisDomain = liveDomain?.code ? liveDomain?.code : userCountryCode === 'US' ? 'AE' : userCountryCode
  // const thisDomain = userCountryCode === 'US' ? 'AE' : userCountryCode
  const attachDomain = countryCode ? countryCode : thisDomain


  useEffect(() => {
    dispatch(addToLanguage({
      languageCode: i18next?.language ? i18next?.language : languageCode ? languageCode : 'en',
      countryCode: attachDomain,
    }));
    document.body.dir = currentLanguage?.dir
  }, [])

  const getCart = useFetchQuery({ userId: userId, cartToken: cartToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  let fontFamilyForAll

  if (isLangaugeCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (isLangaugeCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }

  const fontFamily = {
    fontFamily: fontFamilyForAll,
    textAlign: 'start'
  }

  const { isSigninModal, signinModal } = useSigninModal();
  function userLogout() {
    dispatch(removeToCart());
    dispatch(removeUserToState());
    dispatch(addSelectedSevenDayaPass({ selectedPass: false }));
    history.push("/");
  }

  function onClickLang(data) {
    const findLanguage = Languages?.find((l) => l.code === data)
    document.body.dir = findLanguage?.dir || 'ltr'
    i18next.changeLanguage(data)
    dispatch(addToLanguage({ languageCode: data, countryCode: countryCode }));
    window.open(`/${data}`, '_self');
  }

  const currentLanguageName = Languages?.find((l) => l.code === isLangaugeCode)
  const [getSearchCountry, setSearchCountry] = useState('');
  const [isChnageCountry, setChangeCountry] = useState(false);

  function changeCountry() {
    setChangeCountry(true)
  }

  const [deletecart, deleteresult] = useDeleteMutation()
  async function updateCountry(code) {
    dispatch(addToLanguage({ countryCode: code }));
    window.open(window.location.href, '_self');
  }

  function closeChangeCountry() {
    setChangeCountry(false)
  }

  const searchedCountries = Countries?.filter((l) =>
    l.label?.toLowerCase()?.includes(getSearchCountry?.toLowerCase())
  )

  function fontFamilyFunc(code) {
    let fonts
    if (code === 'ar') {
      fonts = 'GessTwoMedium, sans-serif'
    } else if (code === 'urdu') {
      fonts = 'AlQalam Shakir'
    } else {
      fonts = 'Poppins, sans-serif'
    }
    return {
      fontFamily: fonts
    }
  }

  const [navSize, setnavSize] = useState("auto");
  const [getHomeHeaderMargin, setHomeHeaderMargin] = useState("0px");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavSize("auto") : setnavSize("auto");
    window.scrollY > 10 ? setHomeHeaderMargin("0px") : setHomeHeaderMargin("0px");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const [getSearch, setSearch] = useState('');
  function headerSearch() {
    return (
      <SearchBar
        placeholder={t('search_product')}
        className="header-search-input"
        style={{ margin: !isMobcreen ? '0px 20px' : 0, width: '100%', height: '40px', position: 'relative' }}
        value={getSearch}
        onCancelSearch={() => setSearch('')}
        onChange={(newValue) => setSearch(newValue)}
        onRequestSearch={() => getSearch && history.push(`/search/${getSearch}`)}
        cancelOnEscape={true}
      />
    )
  }

  const [getcartDrawer, cartDrawer] = useState();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    cartDrawer(getcartDrawer)
  }, [getcartDrawer]);

  const stockTimerId = useRef();
  React.useEffect(() => {
    stockTimerId.current = setInterval(() => {
      if (!countryCode) {
        setChangeCountry(true)
      }
    }, 2000);
    return () => {
      clearInterval(stockTimerId.current);
    };
  }, [countryCode]);


  useEffect(() => {
    if (countryCode) {
      setChangeCountry(false)
    }
  }, [countryCode]);

  const list = (anchor) => (
    <Box
      className="side-dialogue-section"
      style={fontFamily}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className='side-menu-list' style={fontFamily}>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/electronics`}>{t('electronics')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/televisions`}>{t('televisions')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/gaming and console`}>{t('gaming_and_console')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/speakers`}>{t('speakers')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/headphonesearphones`}>{t('headphones_earphones')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/home appliances`}>{t('home_appliances')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/music instruments`}>{t('music_instruments')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/entertainments`}>{t('entertainments')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/mobile phones`}>{t('mobile_phones')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/computers`}>{t('computers')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/laptops`}>{t('laptops')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/pc gaming`}>{t('pc_gaming')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/computer accessories`}>{t('computer_accessories')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/clothes`}>{t('clothes')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/watches`}>{t('watches')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/footwear`}>{t('footwear')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/office`}>{t('office')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/healthcare`}>{t('healthcare')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/beauty`}>{t('beauty')}</ListItemButton>
        <ListItemButton className='side-menu-item' component={Link} to={`/search/sports`}>{t('sports')}</ListItemButton>
      </List>
    </Box>
  );

  let thisCountry
  if (countryCode === 'UK') {
    thisCountry = 'GB'
  } else {
    thisCountry = countryCode
  }

  return (
    <>
      {getCart?.error?.originalStatus === 403 &&
        <div style={{ position: 'fixed', right: 0, laeft: 0, bottom: 0, zIndex: 999, display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Alert severity="error">Something went wrong. Please try after sometime.</Alert>
        </div>
      }
      {/* {getChangeDisputeCountry &&
        <div className='country-alert-dialogue-section'>
          <Alert
            className='country-alert-dialogue'
            severity="info"
            onClose={() => cancelChangeDisputeCountry()}>
            <small
              onClick={() => changeDisputeCountry(liveDomain?.code)}>
              You have been redirected to your local country's website. To revert it please click here.</small>
          </Alert>
        </div>
      } */}
      <Modal className="country-modal" show={isChnageCountry} onHide={closeChangeCountry} centered animation={false}>
        <Modal.Body style={{ padding: 0 }}>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={closeChangeCountry}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px', justifyContent: 'center' }}>
                <img
                  style={{ margin: "0 4px" }}
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png 2x`}
                  alt=""
                />
                <h5 style={{ textAlign: 'center', padding: '10px', textTransform: 'capitalize', margin: 0 }}>{t('search_country')}</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group" style={{ borderRadius: '10px', position: 'relative', borderTopRightRadius: '4px', borderTopLeftRadius: '4px' }}>
                <div style={{ position: '-webkit-sticky', position: 'sticky', top: 0, zIndex: 1, padding: '10px' }}>
                  <input
                    style={{ height: '45px', border: '1px solid #dcdcdc' }}
                    onChange={((e) => setSearchCountry(e.target.value))} className='form-control' placeholder={t('search_country')} />
                </div>
                <div style={{ height: '300px', overflow: 'auto', padding: '10px' }}>
                  {searchedCountries?.map((item, i) =>
                    <Button
                      variant='plain'
                      size='sm'
                      disabled={item.code === countryCode}
                      onClick={(() => updateCountry(item.code))}
                      style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', textTransform: 'capitalize' }}
                      key={i}>
                      <div style={{ display: 'flex', alignItems: 'center', padding: '5px 0px 5px 0px', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            style={{ margin: "0 4px" }}
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${item.code?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${item.code?.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          <div style={{ margin: '0 5px' }}>
                            {item.label}
                          </div>
                        </div>
                        {countryCode === item.code &&
                          <div> <GradeIcon style={{ fontSize: '1.4rem' }} /></div>
                        }
                      </div>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="Header-page">
        <div className="Header-menu"
          style={{ height: navSize, transition: "all 0.5s", background: Host?.headerBg, }}>
          <div className="container">
            <div className={`Header-menu-list2 Header-menu-list`} style={{ marginTop: getHomeHeaderMargin }}>
              <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                <div className="Header-menu-logoName">
                  <Link to={`/${languageCode}`}>
                    <img
                      src={Host?.domainLogo}
                      loading="lazy"
                      alt={Host?.domainName}
                      style={{ transition: "all 0.5s", width: '100px' }}
                    />
                  </Link>
                </div>
              </div>
              {!isMobcreen &&
                headerSearch()
              }
              <div className="Header-menu-account">
                <LoginModal isSigninModal={isSigninModal} signinModal={signinModal} />
                <div className="Header-waller-connect1">
                  <Dropdown>
                    <MenuButton
                      style={{ padding: '6px 8px', border: 'none', borderRadius: '6px', fontSize: '1rem' }}
                      className="Header-button-connect">
                      <img
                        style={{ margin: "0px 5px" }}
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${thisCountry?.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${thisCountry?.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      <div className='mobile-hide' style={fontFamily}>
                        <span style={{ textTransform: 'uppercase' }}>{currentLanguageName?.code}</span>
                        <ExpandMoreIcon className="expand-style mobile-hide" style={{ marginTop: '-2px' }} />
                      </div>
                    </MenuButton>
                    <Menu>
                      {Languages?.map((item, i) =>
                        <MenuItem
                          key={i}
                          onClick={() => onClickLang(item.code)}
                          disabled={isLangaugeCode === item.code ? true : false}
                          style={fontFamily}
                        >
                          <div style={fontFamilyFunc(item.code)}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input type='radio' checked={isLangaugeCode === item.code ? true : false} />
                              <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                                <span style={{ textTransform: 'uppercase', fontSize: '14px' }}>{item.code}</span>
                                <span style={{ margin: '0px 5px' }}> - </span>
                                <span style={{ fontSize: '14px' }}>{item.name}</span>
                              </div>
                            </div>
                          </div>
                        </MenuItem>
                      )}
                      <div style={{ padding: '5px 15px', textAlign: 'start' }}>
                      </div>
                      <div style={{ padding: '5px 15px' }}>
                        <div style={{ fontSize: '14px' }}>{t('currency')}:&nbsp;{userData?.contant?.currency}</div>
                        <div style={{ display: 'flex', fontSize: '14px', alignItems: 'center', width: '100%', padding: '5px 0px 5px 0px' }}>
                          <div>{t('shipping_to')}:</div>
                          <img
                            style={{ margin: '0px 5px' }}
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${thisCountry?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${thisCountry?.toLowerCase()}.png 2x`}
                          />
                          <div>{userData?.contant?.country}</div>
                        </div>
                        <Button
                          variant='soft'
                          size='sm'
                          style={{ fontSize: '14px', textTransform: 'capitalize', fontWeight: 400 }}
                          onClick={() => changeCountry()}>
                          <small>{t('change_country')}</small>
                        </Button>
                      </div>
                    </Menu>
                  </Dropdown>
                </div>
                {userEmail && userToken ?
                  <div className="Header-waller-connect1">
                    <Dropdown>
                      <MenuButton
                        style={{ padding: '6px 8px', border: 'none', borderRadius: '6px', fontSize: '1rem' }}
                        className="Header-button-connect">
                        <PermIdentityIcon style={{ margin: '0 3px' }} />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div className='mobile-hide' style={fontFamily}>{t('account')}</div>
                          <ExpandMoreIcon className="expand-style mobile-hide" style={{ margin: 0 }} />
                        </div>
                      </MenuButton>
                      <Menu style={{ minWidth: '200px', maxWidth: '100%' }}>
                        <MenuItem
                          key={'a3'}
                          style={fontFamily}
                          component={Link}
                          to={`/${isLangaugeCode}/account/settings`}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AccountBoxOutlinedIcon style={{ fontSize: '1.2rem' }} />
                            <div style={{ margin: '0px 10px' }}>{t('My Account')}</div>
                          </div>
                        </MenuItem>
                        <MenuItem
                          key={'a1'}
                          style={fontFamily}
                          component={Link}
                          to={`/${isLangaugeCode}/account/orders`}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LocalMallOutlinedIcon style={{ fontSize: '1.2rem' }} />
                            <div style={{ margin: '0px 10px' }}>{t('My Orders')}</div>
                          </div>
                        </MenuItem>
                        <MenuItem
                          key={'a5'}
                          style={fontFamily}
                          onClick={userLogout}
                          component={Link}
                          to={`#`}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ExitToAppOutlinedIcon style={{ fontSize: '1.2rem' }} />
                            <div style={{ margin: '0px 10px' }}>{t('signout')}</div>
                          </div>
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  </div>
                  :
                  <div className="Header-waller-connect1">
                    <Button
                      variant='plain'
                      style={{ textTransform: 'capitalize', padding: '6px 8px', border: 'none', borderRadius: '6px', fontSize: '1rem' }}
                      disabled={!ActiveCountries?.includes(countryCode)}
                      aria-haspopup="true"
                      onClick={(() => ActiveCountries?.includes(countryCode) && signinModal(true))}
                    >

                      <svg style={{ margin: '0 4px' }} width="20" height="20" fill="#5e6e82" className="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                      </svg>
                      <div className="mobile-hide">
                        <span style={fontFamily}>{t('login')}</span>
                      </div>
                    </Button>
                  </div>
                }
                <div className="Header-waller-connect1">
                  {[document.body.dir === 'rtl' ? 'left' : 'right'].map((anchor) => (
                    <Button
                      variant='plain'
                      style={{ textTransform: 'capitalize', padding: '6px 8px', border: 'none', borderRadius: '6px', fontSize: '1rem' }}
                      key={anchor} onClick={(() => cartDrawer(anchor, true))}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                        <svg style={{ margin: '0px 4px' }} width="18" height="18" fill="#5e6e82" className="bi bi-cart" viewBox="0 0 16 16">
                          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                        <div className="mobile-hide">
                          <span style={fontFamily}>{t('cart')}</span>
                        </div>
                        <div
                          className="header-cart-value"
                          style={languageCode === 'ar' ? { left: '-10px' } : { right: '-10px' }}>
                          {getCart?.data?.cart?.length}</div>
                      </div>
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobcreen &&
          headerSearch()
        }
        <div style={{ background: Host?.subMenu, borderTop: '0.7px solid #00000026', borderBottom: '0.7px solid #00000026', display: 'block', width: '100%', position: 'relative', zIndex: -1 }}>
          <div className="container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                {[document.body.dir === 'rtl' ? 'right' : 'left'].map((anchor) => (
                  <IconButton key={anchor} onClick={toggleDrawer(anchor, true)}>
                    <MenuIcon />
                  </IconButton>
                ))}
              </div>
              <div className="menu-section scroll-menu">
                <div className='menu-section scroll-menu' style={{ justifyContent: 'flex-start' }}>
                  <div className='card-section mobile-hide' style={{ overflow: 'unset' }}>
                    <Link to="/" className='sub-menu-header'>{t('home')}</Link>
                  </div>
                  <div className='mobile-hide' style={{ borderRight: '1px solid #eee' }}>&nbsp;</div>
                  <div className='card-section scroll-menu'>
                    <Link to="/search/electronics" className='sub-menu-header'>{t('electronics')}</Link>
                    <Link to="/search/laptops" className='sub-menu-header'>{t('laptops')}</Link>
                    <Link to="/search/phone" className='sub-menu-header'>{t('mobiles')}</Link>
                    <Link to="/search/headphone" className='sub-menu-header'>{t('headphone')}</Link>
                    <Link to="/search/makeup" className='sub-menu-header'>{t('makeup')}</Link>
                    <Link to="/search/haircare" className='sub-menu-header'>{t('haircare')}</Link>
                    <Link to="/search/beauty" className='sub-menu-header'>{t('beauty')}</Link>
                    <Link to="/search/skincare" className='sub-menu-header'>{t('skincare')}</Link>
                    <Link to="/search/fashion" className='sub-menu-header'>{t('fashion')}</Link>
                    <Link to="/search/Games" className='sub-menu-header'>{t('gaming')}</Link>
                    <Link to="/search/accessories" className='sub-menu-header'>{t('accessories')}</Link>
                    <Link to="/search/shoes" className='sub-menu-header'>{t('shoes')}</Link>
                    <Link to="/search/watches" className='sub-menu-header'>{t('watches')}</Link>
                  </div>
                  <div className='mobile-hide' style={{ borderRight: '1px solid #eee' }}>&nbsp;</div>
                </div>
                <div className='card-section mobile-hide' style={{ overflow: 'unset' }}>
                  <Link to="/about-us" className='sub-menu-header'>{t('about')}</Link>
                  <Link to="/contact-us" className='sub-menu-header'>{t('contact')}</Link>
                  <Button
                    variant='plain'
                    size='sm'
                    color='warning'
                    component={Link}
                    to="/store/deals"
                    style={{ minHeight: '30px', maxHeight: '30px' }}
                    className='sub-menu-header'>
                    <small>
                      <strong>
                        <span style={{ background: '#fe7f4a', color: '#fcfcfc', padding: '0px 2px 1px 2px', borderRadius: '2px', margin: '0px 5px' }}>
                          {t('Warehouse deals ')}
                        </span>
                        <span style={{color: '#fe7f4a'}}> 50% Off</span>
                      </strong>
                    </small>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <React.Fragment>
        {[document.body.dir === 'rtl' ? 'right' : 'left'].map((anchor) => (
          <Drawer
            className="side-menu-section side-scroll-menu"
            key={anchor}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 1, padding: '0px', textAlign: document.body.dir === 'rtl' ? 'left' : 'right' }}>
              <ModalClose id="close-icon" sx={{ position: 'initial' }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '6px 15px' }}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${thisCountry?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${thisCountry?.toLowerCase()}.png 2x`}
              />
              <div style={{ padding: '5px 15px' }}><small>{userData?.contant?.country}</small></div>
            </div>
            {list(anchor)}
          </Drawer >
        ))
        }
      </React.Fragment >
      <CartPage getcartDrawer={getcartDrawer} cartDraweraa={cartDrawer} />
    </>
  );
}

export default Header;
